import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToastModule } from 'primeng/toast';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ConfirmationDialogService } from '../../services/confirmation-dialog.service';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-confirmation',
  standalone: true,
  imports: [CommonModule, ToastModule, ConfirmDialogModule, TranslateModule],
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss'],
  providers: [ConfirmationService, MessageService],
  encapsulation: ViewEncapsulation.None,
})
export class ConfirmationComponent implements OnInit {
  header: string = '';
  message: string = '';
  type: 'btnCancel' | 'btnLanguage' | 'btnDeletePlace' | 'btnDeleteDay';
  cancelBtnLabel: string;
  confirmBtnLabel: string;
  responsiveModal: boolean;

  constructor(
    public confirmationService: ConfirmationService,
    public confirmationDialogService: ConfirmationDialogService
  ) {}

  get visible(): boolean {
    return this.confirmationDialogService.confirmationDataSubject.value !== null;
  }

  ngOnInit(): void {
    this.confirmationDialogService.confirmationData$.subscribe((data) => {
      if (data) {
        this.header = data.header;
        this.message = data.message;
        this.type = data.type;
        this.cancelBtnLabel = data.cancelBtnLabel || 'Cancel';
        this.confirmBtnLabel = data.confirmBtnLabel || 'Confirm';
        this.responsiveModal = data.responsiveModal || false;
      }
    });
  }
}
