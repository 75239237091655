<p-dropdown
  class="flex"
  [options]="countries"
  [(ngModel)]="selectedCountry"
  optionLabel="name"
  filterBy="name"
  panelStyle="top:28px; left: -85px"
  (onChange)="changeLocale(selectedCountry)"
>
  <ng-template pTemplate="selectedItem">
    <div class="country" *ngIf="selectedCountry">
      <img
        class="country-flag"
        src="/assets/images/flag/flag_placeholder.png"
        [class]="'flag flag-' + selectedCountry.code.toLowerCase()"
      />
    </div>
  </ng-template>
  <ng-template let-country pTemplate="item">
    <div class="country">
      <img
        class="country-flag"
        src="/assets/images/flag/flag_placeholder.png"
        [class]="'flag flag-' + country.code.toLowerCase()"
      />
      <div class="text-sm font-medium text-gray-700">{{ country.name }}</div>
    </div>
  </ng-template>
</p-dropdown>
