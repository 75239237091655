import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

export interface ConfirmationData {
  header: string;
  message: string;
  type: 'btnCancel' | 'btnLanguage' | 'btnDeletePlace' | 'btnDeleteDay';
  cancelBtnLabel?: string;
  confirmBtnLabel?: string;
  responsiveModal?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class ConfirmationDialogService {
  private isDialogVisible: boolean = false;
  public confirmationDataSubject: BehaviorSubject<ConfirmationData | null> =
    new BehaviorSubject<ConfirmationData | null>(null);
  public confirmationData$: Observable<ConfirmationData | null> =
    this.confirmationDataSubject.asObservable();
  private acceptCallback: (() => void) | null = null;
  private rejectCallback: (() => void) | null = null;

  isConfirmationDialogVisible(): boolean {
    return this.isDialogVisible;
  }

  setConfirmationData(
    data: ConfirmationData,
    acceptCallback: (() => void) | null = null,
    rejectCallback: (() => void) | null = null
  ): void {
    this.confirmationDataSubject.next(data);
    this.acceptCallback = acceptCallback;
    this.rejectCallback = rejectCallback;
  }

  clearConfirmationData(): void {
    this.confirmationDataSubject.next(null);
    this.acceptCallback = null;
    this.rejectCallback = null;
  }

  executeAcceptCallback(): void {
    if (this.acceptCallback) {
      this.acceptCallback();
    }
    this.clearConfirmationData();
  }

  executeRejectCallback(): void {
    if (this.rejectCallback) {
      this.rejectCallback();
    }
    this.clearConfirmationData();
  }
}
