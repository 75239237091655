import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanDeactivateFn,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import {
  ConfirmationData,
  ConfirmationDialogService,
} from '@shared/services/confirmation-dialog.service';
import { Observable } from 'rxjs';

export interface CanComponentDeactivate {
  canDeactivate: (
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState: RouterStateSnapshot
  ) => boolean | Observable<boolean>;
}

export const CanDeactivateGuard: CanDeactivateFn<CanComponentDeactivate> = (
  component: CanComponentDeactivate,
  currentRoute: ActivatedRouteSnapshot,
  currentState: RouterStateSnapshot,
  nextState: RouterStateSnapshot
): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => {
  const canDeactive = component.canDeactivate(currentRoute, currentState, nextState);

  if (!canDeactive) {
    const confirmService: ConfirmationDialogService = inject(ConfirmationDialogService);

    return new Observable<boolean>((observer) => {
      let confirmationData: ConfirmationData = {
        header: 'common.confirm_message.change_found_header',
        message: 'common.confirm_message.change_found_content',
        type: 'btnCancel',
      };

      confirmService.setConfirmationData(
        confirmationData,
        () => {
          observer.next(true);
          observer.complete();
        },
        () => {
          observer.next(false);
          observer.complete();
        }
      );
    });
  } else {
    return true;
  }
};
