<div class="t-main-header w-full h-12 md:h-16 {{ config.backgroundStyleClass }} ">
  <div class="flex items-center justify-between h-full px-3 md:px-5">
    <!-- Toggle sidebar & Icon logo -->
    <div class="flex items-center gap-1">
      <!-- <button class="sctr-icon-menu-01 icon-white h-11 aspect-square"></button> -->
      <a class="flex items-center gap-3" [routerLink]="'/'">
        <img [src]="config.logo" alt="logo" class="md:h-6 h-4 text-white" />
        <span class="md:text-xl text-lg text-white font-medium">Admin</span>
      </a>
    </div>

    <!-- Notification & Avatar icon -->
    <div class="relative flex items-center gap-x-1 md:gap-x-2">
      <!-- <span class="relative inline px-2 py-0.5 rounded-md cursor-pointer">
        <i class="text-lg md:text-2xl sctr-icon-bell-01 icon-notification"></i>
        <div class="absolute top-0.5 right-0.5 text-center md:top-1 md:right-1">
          <div
            class="rounded-full text-[8px] min-w-[14px] aspect-square font-bold leading-[10px] bg-[#E62E05] py-0.5 px-1 text-white"
          >
            4
          </div>
        </div>
      </span> -->
      <div class="relative inline px-2 py-0.5 rounded-md cursor-pointer">
        <app-flags-dropdown (languageChange)="changeLanguage($event)"></app-flags-dropdown>
      </div>

      <div class="relative p-0.5">
        <div
          class="md:w-[38px] md:h-[38px] w-6 h-6 rounded-full bg-gradient-to-r from-[#FEAD34] to-[#FF7041] p-[1px]"
          (click)="toggle()"
        >
          <div class="h-full overflow-hidden bg-white border-2 border-white rounded-full">
            <div class="absolute top-0 right-0.5 p-[1px] bg-white rounded-full">
              <img src="assets/svg/crown_icon.svg" />
            </div>
            <div class="rounded-full">
              <img [src]="avatarService.getData() | async" />
            </div>
          </div>
          <p-overlay
            class="absolute left-[-220px] top-4"
            [(visible)]="overlayVisible"
            [styleClass]="'z-10 min-w-0 top-0 left-0'"
          >
            <div class="w-[260px] bg-white p-0 border border-gray-200 rounded-lg">
              <ul class="px-[6px] py-1 flex flex-col gap-1">
                <li
                  class="p-[9px] flex items-center gap-2 text-red-600 hover:bg-gray-200 cursor-pointer"
                  (click)="displayPopupLogout()"
                >
                  <img src="assets/icons/log-out-01.svg" alt="Sign out" />
                  <span>Sign out</span>
                </li>
              </ul>
            </div>
          </p-overlay>
        </div>
      </div>
    </div>
  </div>
</div>
<p-dialog
  header="Sign out of your account"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  [(visible)]="visiblePopupLogout"
  [style]="{ width: '400px' }"
  [styleClass]="'border-0'"
>
  <div class="flex justify-between gap-3 px-6 pb-6 pt-11">
    <p-button
      class="w-1/2"
      [styleClass]="
        'p-button-secondary text-base w-full px-[18px] text-palette-gray-700 py-2.5 justify-center'
      "
      (click)="closePopupLogout()"
      >Cancel</p-button
    >
    <p-button
      class="w-1/2"
      [loading]="loadingSignOut"
      [styleClass]="'p-button-danger text-base w-[100%] px-[18px] py-2.5 justify-center'"
      (click)="handleSignOut()"
      >Sign out</p-button
    >
  </div>
</p-dialog>
