<div class="t-confirmation" [ngClass]="{ 't-responsive-modal': responsiveModal }">
  <p-confirmDialog styleClass="t-custom-dialog" [message]="message | translate" [visible]="visible">
    <ng-template pTemplate="header">
      <div class="flex justify-between w-full items-center">
        <span class="text-gray-900 text-lg font-semibold self-stretch">{{
          header | translate
        }}</span>
        <button
          type="button"
          class="flex justify-center items-center hover:bg-gray-50 absolute right-4 top-4 hover:rounded-2xl p-2.5"
          (click)="confirmationDialogService.clearConfirmationData()"
        >
          <span class="pi pi-times"></span>
        </button>
      </div>
    </ng-template>
    <ng-template pTemplate="footer">
      <div class="w-full flex justify-end items-center gap-3 self-stretch">
        <button
          class="!text-gray-700 !bg-white hover:!bg-gray-50 hover:!text-gray-600 rounded-lg"
          type="button"
          pButton
          [label]="cancelBtnLabel"
          (click)="
            confirmationDialogService.executeRejectCallback();
            confirmationDialogService.clearConfirmationData()
          "
        ></button>
        <button
          class="!text-white border-none rounded-lg border"
          [ngClass]="{
            '!bg-error-500 hover:!bg-error-600':
              type === 'btnCancel' || type === 'btnDeletePlace' || type === 'btnDeleteDay',
            '!bg-primary-600 hover:!bg-primary-500':
              type !== 'btnCancel' && type !== 'btnDeletePlace' && type !== 'btnDeleteDay'
          }"
          type="button"
          pButton
          [label]="confirmBtnLabel"
          (click)="
            confirmationDialogService.executeAcceptCallback();
            confirmationDialogService.clearConfirmationData()
          "
        ></button>
      </div>
    </ng-template>
  </p-confirmDialog>
</div>
