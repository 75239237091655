import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { TokenStorageService } from 'src/app/services/token-storage.service';
import { BehaviorSubject, of } from 'rxjs';
import { UserService } from '../user/user.service';
import { UserService as User } from '../user.service';
import { ModerateRole } from '@shared/constant';
import { Location as LocationAngular } from '@angular/common';
import { AppRoutes } from 'src/app/config/routes.config';
@Injectable({
  providedIn: 'root',
})
export class PermissionService {
  public userRoles = new BehaviorSubject<any>(this.userService.getUserInfoFromLocalStorage());

  constructor(
    private tokenStorageService: TokenStorageService,
    private authService: AuthService,
    private userService: UserService,
    private user: User,
    private location: LocationAngular
  ) {}

  checkPermission(requiredRoles: string[], roles: string[]) {
    const tokenId = this.tokenStorageService.getToken(); // || get token from cookie
    const isTokenValid = this.authService.isTokenExpired();
    // Check if the token is expired or invalid
    if (tokenId && !isTokenValid) {
      const hasPermission = requiredRoles.some((permission) => roles.includes(permission));
      return hasPermission;
    }
    return false;
  }
  async checkPermission1(token?: string): Promise<boolean> {
    const locationUrl = this.location.path().split('/')[1];
    return new Promise<boolean>(async (resolve) => {
      let hasPermission = false;
      let tokenId: string | null = '';
      if (token) {
        tokenId = token;
      } else {
        tokenId = this.tokenStorageService.getToken();
      }

      if (tokenId) {
        try {
          if (locationUrl && locationUrl !== AppRoutes.ADMIN) {
            hasPermission = true;
          } else {
            const res = await this.user.getModeratorRole().toPromise();
            if (res && res.length > 0) {
              hasPermission = res.some(
                (item) =>
                  item.role === ModerateRole.SUPER_ADMIN.code ||
                  (item.role === ModerateRole.ADMIN.code &&
                    item.type === 'MODULE_ADMIN' &&
                    item.type_moderator === 'BANNER_MODULE_ADMIN')
              );
            }
          }
        } catch (error) {
          console.error(error);
        }
      }
      return resolve(hasPermission);
    });
  }
}
