import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { TreeModule } from 'primeng/tree';
import { TreeNode } from 'primeng/api';
import { SubModuleItem } from '@soctrip/angular-banner-service';

@Component({
  selector: 'app-tree-node',
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  imports: [CommonModule, TreeModule],
  templateUrl: './tree-node.component.html',
  styleUrls: ['./tree-node.component.scss'],
})
export class TreeNodeComponent implements OnChanges {
  @Input() selectionMode: 'multiple' | 'single' | 'checkbox' = 'single';
  @Input() treeData: any[] = [];
  @Output() nodeSelectEvent = new EventEmitter<SubModuleItem>();
  // treeNodes: any = TreeData;
  selectedNode: SubModuleItem | undefined;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['treeData'] && changes['treeData'].currentValue.length) {
      this.treeData?.forEach((node) => {
        this.expandRecursive(node, true);
      });
      this.selectedNode = this.treeData?.[0]?.children?.[0];
      this.nodeSelectEvent.emit(this.treeData?.[0]?.children?.[0]);
    }
  }

  onNodeSelect(node: SubModuleItem): void {
    if (node.code) {
      this.nodeSelectEvent.emit(node);
    }
  }
  private expandRecursive(node: TreeNode, isExpand: boolean) {
    node.expanded = isExpand;
    if (node.children) {
      node.children.forEach((childNode) => {
        this.expandRecursive(childNode, isExpand);
      });
    }
  }
}
