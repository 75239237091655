<div class="flex flex-col w-full t-main-layout">
  <div class="flex flex-col w-full">
    <app-header
      *ngIf="treeModule === adminModule"
      class="sticky top-0 z-50 flex-initial shadow-sm"
    ></app-header>
    <div class="flex">
      <!-- <div class="w-[68px] fixed top-16 left-0 h-[calc(100vh_-_64px)]">
        <app-sidebar></app-sidebar>
      </div> -->
      <div class="relative w-full bg-gray-50 flex min-h-[calc(100vh_-_64px)]">
        <div class="w-[1800px] max-w-[calc(100%_-_48px)] mx-auto">
          <div class="flex justify-between items-center flex-col md:flex-row">
            <h1 class="text-2xl font-semibold text-gray-900 my-6">
              {{ 'common.component.app_main_layout.banner_manager' | translate }}
            </h1>
            <p-dropdown
              [options]="countries"
              [(ngModel)]="selectedCountry"
              (onChange)="handleChangeCountry($event)"
              optionLabel="country"
              class="t-dropdown-select-country w-full md:w-[320px]"
              styleClass="rounded-lg px-[14px] py-2 w-full h-9"
            >
              <ng-template pTemplate="selectedItem">
                <div class="font-semibold text-gray-900 text-md" *ngIf="selectedCountry">
                  <span class="dropdown-text">{{ selectedCountry.country }}</span>
                </div>
              </ng-template>
              <ng-template let-countryItem pTemplate="item">
                <div
                  class="flex items-center justify-between w-full gap-2 p-3 font-medium bg-white dropdown-list h-11 text-md"
                >
                  <div class="text-gray-700">
                    <span>{{ countryItem.country }}</span>
                  </div>
                  <i
                    id="check-icon"
                    class="pi pi-check h-[18px] w-4 text-primary-600"
                    *ngIf="selectedCountry.id === countryItem.id"
                  ></i>
                </div>
              </ng-template>
            </p-dropdown>
          </div>

          <div class="flex flex-col md:flex-row gap-y-4 border rounded-lg bg-white my-4">
            <div class="w-full md:w-[300px] md:min-h-[calc(100vh_-_150px)] flex-shrink-0 snap-x">
              <app-tree-node
                [treeData]="treeData"
                (nodeSelectEvent)="nodeSelectEventHandle($event)"
              ></app-tree-node>
            </div>
            <div class="w-full md:w-[calc(100%_-_300px)]">
              <router-outlet></router-outlet>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
