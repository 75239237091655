import { Routes } from '@angular/router';
import { TasksService } from '@modules/todos/services/tasks.service';
import { PermissionGuard } from './core/guards/permission/permission.guard';
import { USER_PERMISSION, MANAGER_PERMISSION } from './shared';
import { AppRoutes } from './config/routes.config';
import { MainLayoutComponent } from '@layout/main-layout/main-layout.component';
import { CanDeactivateGuard } from './core/guards/can-deactivate/can-deactivate.guard';
import { AuthGuard } from './core/guards/auth.guard';

export const APP_ROUTES: Routes = [
  {
    path: AppRoutes.LOGIN,
    title: 'Login',
    loadComponent: () =>
      import('@modules/login/login.component').then(({ LoginComponent }) => LoginComponent),
  },
  {
    path: '',
    redirectTo: AppRoutes.ADMIN,
    pathMatch: 'full',
  },
  {
    path: ':nameModule',
    component: MainLayoutComponent,
    children: [
      {
        path: '',
        title: 'Banner Manager',
        loadComponent: () =>
          import('@modules/banner-manager/banner-manager.component').then(
            ({ BannerManagerComponent }) => BannerManagerComponent
          ),
      },
      {
        path: ':codeBanner',
        title: 'Banner Manager',
        loadComponent: () =>
          import('@modules/banner-manager/banner-manager.component').then(
            ({ BannerManagerComponent }) => BannerManagerComponent
          ),
      },
      {
        path: `:codeBanner/${AppRoutes.ADD_BANNER}`,
        title: 'Add banner',
        loadComponent: () =>
          import('@modules/banner-form/banner-form.component').then(
            ({ BannerFormComponent }) => BannerFormComponent
          ),
        canDeactivate: [CanDeactivateGuard],
      },
      {
        path: `:codeBanner/${AppRoutes.EDIT_BANNER}`,
        title: 'Edit banner',
        loadComponent: () =>
          import('@modules/banner-form/banner-form.component').then(
            ({ BannerFormComponent }) => BannerFormComponent
          ),
        canDeactivate: [CanDeactivateGuard],
      },
    ],
    // canActivate: [AuthGuard],
  },

  {
    path: '**',
    title: 'Page Not Found',
    loadComponent: () =>
      import('./layout/page-not-found/page-not-found.component').then(
        ({ PageNotFoundComponent }) => PageNotFoundComponent
      ),
  },
];
