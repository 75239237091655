export const ROLES = {
  user: 'user',
  manager: 'manager',
  admin: 'admin',
};
export const USER_PERMISSION = [ROLES.user, ROLES.manager, ROLES.admin];
export const MANAGER_PERMISSION = [ROLES.admin, ROLES.manager];
export const ADMIN_PERMISSION = [ROLES.admin];
export const ModerateRole = {
  SUPER_ADMIN: {
    name: 'Super Admin',
    code: 'SUPER_ADMIN',
  },
  ADMIN: {
    name: 'Admin',
    code: 'ADMIN',
  },
  MANAGER: {
    name: 'Manager',
    code: 'MANAGER',
  },
  LEADER: {
    name: 'Leader',
    code: 'LEADER',
  },
  MEMBER: {
    name: 'Member',
    code: 'MEMBER',
  },
  COLLABORATORS: {
    name: 'Collaborators',
    code: 'COLLABORATORS',
  },
};
