import { DropdownModule } from 'primeng/dropdown';
import {
  Component,
  EventEmitter,
  Input,
  NO_ERRORS_SCHEMA,
  OnInit,
  Output,
  inject,
} from '@angular/core';
import { Countries } from 'src/app/core/models/classes/countries';
import { CustomTranslateService } from 'src/app/core/services/user/custom-translate.service';
import { SentenceCasePipe } from 'src/app/core/pipes/sentence-case.pipe';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-flags-dropdown',
  templateUrl: './flags-dropdown.component.html',
  styleUrls: ['./flags-dropdown.component.scss'],
  standalone: true,
  imports: [DropdownModule, CommonModule, FormsModule],
  providers: [Countries, SentenceCasePipe],
  schemas: [NO_ERRORS_SCHEMA],
})
export class FlagsDropdownComponent implements OnInit {
  selectedCountry: any;
  @Input() language: string;
  @Output() languageChange: EventEmitter<any> = new EventEmitter();

  countries = inject(Countries).data;
  translator = inject(CustomTranslateService);

  constructor() {}

  ngOnInit(): void {
    this.language = this.language || (this.translator.getCurrentLanguage() as string);
    this.selectedCountry = this.countries.find((c: any) => c.language === this.language);
  }

  changeLocale(country: any) {
    const language = country.language;
    this.languageChange.emit(language);
  }
}
