import { Injectable } from '@angular/core';
import { BehaviorSubject, distinctUntilChanged, share } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class CountryService {
  constructor() {}
  private country$ = new BehaviorSubject<string>('');

  getCountry() {
    return this.country$.asObservable().pipe(distinctUntilChanged(), share());
  }

  setCountry(countryCode: string) {
    this.country$.next(countryCode);
  }
}
