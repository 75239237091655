import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  basePath = environment.baseURL;
  constructor(private http: HttpClient) {}
  getUserInfo(id: string) {
    return this.http.get(`${this.basePath}/user/users/` + id);
  }
  saveUserInfoToLocalStorage(userRole: string[]) {
    localStorage.setItem(environment.USER_ROLE_KEY, JSON.stringify(userRole));
  }
  getUserInfoFromLocalStorage() {
    const userInfoString = localStorage.getItem(environment.USER_ROLE_KEY);
    if (userInfoString) return JSON.parse(userInfoString);
  }
  clearUserLocalStorage() {
    localStorage.removeItem(environment.USER_ROLE_KEY);
  }
  saveFrofile(profile: any) {
    localStorage.removeItem(environment.USER_PROFILE);
    localStorage.setItem(environment.USER_PROFILE, profile);
  }
}
