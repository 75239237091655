import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class BannerFormService {
  private treeData = new BehaviorSubject<any[]>([]);
  private treeData$ = this.treeData.asObservable();

  constructor() {}

  setTreeData(data: any[]) {
    this.treeData.next(data);
  }

  getTreeData(): Observable<any[]> {
    return this.treeData$;
  }

  findChildrenByCode(treeData: any[], code: string) {
    for (let i = 0; i < treeData?.length; i++) {
      const children = treeData?.[i]?.children;
      const foundChild = children?.find((child) => child.code === code);
      if (foundChild) {
        foundChild.module = treeData?.[i].name;
        return foundChild;
      }
    }
    return null;
  }

  generateTextFormStructure(reference_text_link: ObjectText[] = []): FormStructure[] {
    const formStructure: FormStructure[] = [];
    for (let i = 1; i <= reference_text_link.length; i++) {
      formStructure.push({
        name: `code${i}`,
        label: `Title ${i}`,
        placeholder: `Enter title ${i}`,
      });
      formStructure.push({
        name: `link${i}`,
        placeholder: `Enter title URL ${i}`,
      });
    }
    return formStructure;
  }

  generateButtonFormStructure(cta_button: ObjectText[] = []): FormStructure[] {
    const formStructure: FormStructure[] = [];
    for (let i = 1; i <= cta_button.length; i++) {
      formStructure.push({
        name: `cta${i}`,
        label: `CTA button ${i}`,
        placeholder: `Enter call to action text ${i}`,
      });
      formStructure.push({
        name: `cta_link${i}`,
        placeholder: `Enter call to action URL ${i}`,
      });
    }
    return formStructure;
  }
}

export interface FormStructure {
  name: string;
  label?: string;
  placeholder: string;
}

export interface ObjectText {
  [key: string]: string;
}

export interface ImageInfo {
  id: string;
  name: string;
  type: string;
  imageUrl: string;
  extension?: string;
}

export interface TempImage {
  url: string;
  name: string;
  size: string;
}
