import { Component, Input, inject } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { MenubarModule } from 'primeng/menubar';
import { TOP_BAR_MENU } from 'src/app/config/topbar-menu.config';
import { SlideMenuModule } from 'primeng/slidemenu';
import { PermissionDirective } from 'src/app/core/directives/permission.directive';
import { USER_PERMISSION } from '../../shared/constant';
import { ButtonModule } from 'primeng/button';
import { OverlayModule } from 'primeng/overlay';
import DropdownComponent from '@shared/components/dropdown/dropdown.component';
import { AppRoutes } from 'src/app/config/routes.config';
import { Router } from '@angular/router';
import { cloneElement } from 'react';
import { AvatarService } from '@shared/services/avatar.service';
import { CommonModule } from '@angular/common';
import { DialogModule } from 'primeng/dialog';
import { AuthService } from 'src/app/services/auth.service';
import { FlagsDropdownComponent } from '@shared/components/dropdown/flags-dropdown/flags-dropdown.component';
import { CustomTranslateService } from 'src/app/core/services/user/custom-translate.service';
import { environment } from '@env/environment';
export interface IHeaderConfig {
  logo: string;
  backgroundStyleClass?: string;
  mobileMenuStyleClass?: string;
}
@Component({
  selector: 'app-header',
  standalone: true,
  imports: [
    CommonModule,
    /* @primeng */
    MenubarModule,
    SlideMenuModule,
    ButtonModule,
    OverlayModule,
    ButtonModule,
    /* Directives */
    PermissionDirective,
    /* Components */
    DropdownComponent,
    DialogModule,
    FlagsDropdownComponent,
  ],
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  @Input() config: IHeaderConfig = {
    logo: 'assets/svg/soctrip-logo-full-white.svg',
    backgroundStyleClass: 'bg-primary-600',
    mobileMenuStyleClass: 'bg-primary-600',
  };

  public allowPermission = USER_PERMISSION;

  routes = AppRoutes;
  adminHubAppConnectUrl: string = environment.ADMIN_APP_URL + '/app-connect';
  overlayVisible: boolean = false;
  visiblePopupLogout = false;
  loadingSignOut = false;
  translator = inject(CustomTranslateService);

  constructor(
    public avatarService: AvatarService,
    private authService: AuthService,
    private router: Router
  ) {}

  toggle() {
    this.overlayVisible = !this.overlayVisible;
  }

  displayPopupLogout(): void {
    this.visiblePopupLogout = true;
  }

  closePopupLogout(): void {
    this.visiblePopupLogout = false;
  }

  handleSignOut(): void {
    const userProfileId = JSON.parse(localStorage.getItem(environment.USER_PROFILE) || '{}')?.id;
    const currentDeviceInfo = JSON.parse(localStorage.getItem('device_sign_in') || '{}');

    this.loadingSignOut = true;
    this.connectPMServer();
    this.authService
      .logout({
        user_id: userProfileId,
        device: currentDeviceInfo?.device,
        device_id: currentDeviceInfo?.device_id,
        device_type: currentDeviceInfo?.device_type,
      })
      .subscribe();
    setTimeout(() => {
      this.loadingSignOut = false;
      localStorage.clear();
      window.location.href = `${environment.ADMIN_APP_URL}/login`;
      // this.router.navigate(['login']);
    }, 1000);
  }

  connectPMServer() {
    const ifr = document.createElement('iframe');
    ifr.classList.add('hidden');
    document.body.appendChild(ifr);
    ifr.src = this.adminHubAppConnectUrl;
    const connect = () => {
      ifr.contentWindow?.postMessage({ type: 'disconnect' }, '*');
    };
    ifr.onload = connect;
  }

  changeLanguage($event: any) {
    this.translator.setLanguage($event);
  }
}
