import { HttpClient, HttpClientModule } from '@angular/common/http';
import { importProvidersFrom, ApplicationConfig } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter } from '@angular/router';
import { APP_ROUTES } from './app.routes';
import { AUTH_INTERCEPTOR_PROVIDER } from './core/interceptors/auth/auth.interceptor';
import { environment } from '../environments/environment';
import { provideEnvironmentNgxMask } from 'ngx-mask';
import {
  ApiModule as BannerModuleNpm,
  Configuration as BannerConfigurationNpm,
} from '@soctrip/angular-banner-service';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
export function ApiConfigFactory(path: string) {
  return {
    // set configuration parameters here.};
    basePath: `${environment.baseURL}/${path}`,
  };
}

export const APP_CONFIG: ApplicationConfig = {
  providers: [
    importProvidersFrom([
      /* @angular */
      HttpClientModule,
      ReactiveFormsModule,
      BannerModuleNpm.forRoot(() => new BannerConfigurationNpm(ApiConfigFactory('banner'))),
      TranslateModule.forRoot({
        defaultLanguage: 'en',
        useDefaultLang: true,
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient],
        },
      }),
    ]),
    provideEnvironmentNgxMask(),
    provideAnimations(),
    provideRouter(APP_ROUTES),
    AUTH_INTERCEPTOR_PROVIDER,
  ],
};
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
