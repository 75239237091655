<div class="t-tree-banner card flex justify-content-center h-full">
  <p-tree
    class="w-full md:w-30rem"
    styleClass="bg-transparent"
    expanded="true"
    [value]="treeData"
    [selectionMode]="selectionMode"
    [(selection)]="selectedNode"
  >
    <ng-template let-node pTemplate="default">
      <div class="{{ node.code }}" (click)="onNodeSelect(node)">
        {{ node.label }}
      </div>
    </ng-template>
  </p-tree>
</div>
